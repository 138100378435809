<template>
  <b-button-group :size="size" class="d-flex" :style="groupStyles">
    <b-button
      v-for="region in regions"
      :key="region"
      :id="region"
      :variant="isRegionSelected(region) ? 'primary' : ''"
      @click="regionClicked(region)"
      :disabled="disabled"
    >
      {{ region }}
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'RegionFilterButtonGroup',
  props: {
    regions: {
      type: Array,
      required: true,
      validator: value => value.length > 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    width: {
      type: [String, Number],
      default: '100px',
    },
    gap: {
      type: [String, Number],
      default: '2px',
    },
    buttonWidth: {
      type: [String, Number],
      default: '33px',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    // wird dazu benötigt, wenn es 2 oder mehr regionen gibt
    effectiveMaxSelections() {
      return this.maxSelections || this.regions.length;
    },
    groupStyles() {
      return {
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        gap: typeof this.gap === 'number' ? `${this.gap}px` : this.gap,
      };
    },
  },
  methods: {
    // für die variant
    isRegionSelected(region) {
      // hier wird "value" benutzt, da es aus dem prop des v-models kommt
      return this.value.includes(region);
    },

    regionClicked(region) {
      // leeres array damit die ausgewählten regions gespeichert werden
      let newSelectedRegions = [];

      if (this.regions.length === 2) {
        // Wenn die geklickte Region bereits ausgewählt ist, wird die Auswahl geleert.
        if (this.isRegionSelected(region)) {
          newSelectedRegions = [];
        } else {
          // sonst wird die region hinzugefügt
          newSelectedRegions = [region];
        }
        // wenn es mehr als 2 Regions gibt
      } else {
        // wenn 2 regions ausgewählt sind und die 3 geklickt wird
        if (this.value.length === 2 && !this.isRegionSelected(region)) {
          // wird newSelectedRegions "geleert"
          newSelectedRegions = [];
        } else if (this.isRegionSelected(region)) {
          // wenn bereits vorhanden, dann wird die region entfernt
          newSelectedRegions = this.value.filter(r => r !== region);
        } else {
          // Es wird eine neue region hinzugefügt
          newSelectedRegions = [...this.value, region];
        }
      }

      this.$emit('input', newSelectedRegions);
    },
  },
};
</script>
