<template>
  <div class="ag-floating-filter-input" style="font-size: 12px">
    <date-picker
      v-model="currentValue"
      @input="onInput"
      @calendar-change="onCalChange"
      :shortcuts="shortcuts"
      type="date"
      :lang="langConfig"
      input-class="ag-input-field-input ag-text-field-input"
      format="DD.MM.YY"
      range-separator="-"
      range
      ref="agDatepicker"
    >
      <template v-slot:footer="{ emit }">
        <div class="text-center">
          <button
            class="mx-btn mx-btn-text"
            v-for="(month, index) in months"
            :key="'cal-short-btn-month-' + month"
            @click="selectMonth(emit, month, index)"
          >
            {{ month }}
          </button>
        </div>
        <div class="text-center">
          <button
            class="mx-btn mx-btn-text"
            v-for="(quarter, index) in quarters"
            :key="'cal-short-btn-quarter-' + quarter"
            @click="selectQuarter(emit, quarter, index)"
          >
            {{ quarter }}
          </button>
        </div>
        <div class="text-center">
          <button
            class="mx-btn mx-btn-text"
            v-for="(year, index) in years"
            :key="'cal-short-btn-year-' + year"
            @click="selectYear(emit, year, index)"
          >
            {{ year }}
          </button>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import { add, parse, format, sub } from 'date-fns';
import DatePicker from 'vue2-datepicker';
const moment = require('moment');
import 'vue2-datepicker/index.css';

export default {
  name: 'DateRangePicker',
  components: { DatePicker },
  data() {
    return {
      params: null,
      currentValue: null,
      shortcuts: [
        {
          text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.HEUTE'),
          onClick: () => {
            const date = new Date();
            this.calendarSelectedYear = date.getFullYear();
            return [date, date];
          },
        },
        {
          text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.JAHRESENDE'),
          onClick: () => {
            const start = new Date();
            const end = new Date(new Date().getFullYear(), 11, 31);
            this.calendarSelectedYear = start.getFullYear();
            return [start, end];
          },
        },
        {
          text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.ANFANGJAHR'),
          onClick: () => {
            const start = new Date(new Date().getFullYear(), 0, 1);
            this.calendarSelectedYear = start.getFullYear();
            return [start, start];
          },
        },
        {
          text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.INMONATE'),
          onClick: () => {
            const start = new Date();
            const newDate = new Date();
            const end = new Date(newDate.setMonth(newDate.getMonth() + 3));
            this.calendarSelectedYear = start.getFullYear();
            return [start, end];
          },
        },
      ],
      langConfig: {
        formatLocale: {
          months: moment.localeData().months(),
          monthsShort: moment.localeData().monthsShort(),
          weekdays: moment.localeData().weekdays(),
          weekdaysShort: moment.localeData().weekdaysShort(),
          weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          firstDayOfWeek: 1,
        },
      },
      calendarSelectedYear: new Date().getFullYear(),
    };
  },
  computed: {
    months() {
      return Array.apply(0, Array(12)).map(function (_, i) {
        return moment().month(i).format('MMM');
      });
    },
    quarters() {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      const firstOfCurrentYear = parse('01/01/' + currentYear, 'MM/dd/yyyy', new Date());

      return Array.apply(0, Array(12)).map(function (_, i) {
        return format(add(firstOfCurrentYear, { months: i * 3 }), 'QQQ/yy');
      });
    },
    years() {
      const currentYear = new Date().getFullYear();
      const yearRange = 3;
      return Array.from({ length: yearRange * 2 + 1 }, (_, i) => currentYear - yearRange + i);
    },
  },
  mounted() {
    if (this.params?.isReisetermineStartdatum) {
      const start = new Date();
      let end;

      if (start.getFullYear() === 2024) {
        end = new Date(2025, 11, 31);
      } else {
        end = new Date(start.getFullYear(), 11, 31);
      }
      this.currentValue = [start, end];
      this.onInput();
    }
  },
  methods: {
    onCalChange(date) {
      this.calendarSelectedYear = date.getFullYear();
    },
    onInput() {
      // check if params has function parentFilterInstance
      if (this.params.isChild) {
        this.params.parentFilterInstance(instance => {
          instance.onDateRangeFilterChanged(this.currentValue);
        });
      } else {
        this.params.filterChangedCallback();
      }
    },
    onDateRangeFilterChanged(currentValue) {
      this.currentValue = currentValue;
      this.params.filterChangedCallback();
    },
    selectQuarter(emit, quarterLabel, index) {
      this.$refs.agDatepicker.closePopup();
      const quarter = quarterLabel.substring(1, 2);
      const year = quarterLabel.substring(3, 5);
      const firstDay = new Date(`20${year}`, (quarter - 1) * 3, 1);
      const lastDay = sub(new Date(`20${year}`, 3 + (quarter - 1) * 3, 1), { days: 1 });
      this.currentValue = [firstDay, lastDay];
      this.onInput();
    },
    selectMonth(emit, month, index) {
      this.$refs.agDatepicker.closePopup();
      const y = this.calendarSelectedYear,
        m = index;
      const firstDay = new Date(y, m, 1);
      const lastDay = new Date(y, m + 1, 0);
      this.currentValue = [firstDay, lastDay];
      this.onInput();
    },
    selectYear(emit, year) {
      this.$refs.agDatepicker.closePopup();
      const firstDay = new Date(year, 0, 1);
      const lastDay = new Date(year, 11, 31);
      this.calendarSelectedYear = year;
      this.currentValue = [firstDay, lastDay];
      this.onInput();
    },
    getModel() {
      if (!this.isFilterActive()) {
        return null;
      }
      const dateFrom = new Date(this.currentValue[0]);
      const dateTo = new Date(this.currentValue[1]);
      if (isNaN(dateFrom.getTime()) && isNaN(dateTo.getTime())) {
        return null;
      } else {
        return {
          dateFrom: moment(dateFrom).format('YYYY-MM-DD 00:00:00'),
          dateTo: moment(dateTo).format('YYYY-MM-DD 00:00:00'),
          filterType: 'date',
          type: 'inRange',
        };
      }
    },
    setModel(model) {
      if (!model) {
        return null;
      }
      const dateFrom = new Date(model.dateFrom);
      const dateTo = new Date(model.dateTo);
      // check dateFrom is invalid date
      if (isNaN(dateFrom.getTime()) && isNaN(dateTo.getTime())) {
        this.currentValue = [null, null];
      } else {
        this.currentValue = [dateFrom, dateTo];
      }
    },
    isFilterActive() {
      return (
        Array.isArray(this.currentValue) &&
        this.currentValue[0] instanceof Date &&
        this.currentValue[1] instanceof Date
      );
    },
    // Wenn der Filter in der Toolbar gesetzt wird
    onParentModelChanged(parentModel, event) {
      // wir sind auf floating filter ebene
      if (parentModel && this.params.isChild) {
        this.currentValue = [new Date(parentModel.dateFrom), new Date(parentModel.dateTo)];
      }
      if (!parentModel) {
        this.currentValue = null;
      }
    },
  },
};
</script>

<style scoped>
:deep(.mx-datepicker) {
  width: auto;
}
:deep(.mx-datepicker-range) {
  width: auto;
}
</style>
